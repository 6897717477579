import React from 'react'
import { Card, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import type * as Store from 'types/store'
import { BoxType } from 'types/enums'
import { config, isNumeric, toNumber } from 'core'
import { getBoxTitle } from 'core/labelHelper'

import { ReactComponent as ExportIcon } from 'assets/icon/export-icon.svg'
import { ReactComponent as ImportIcon } from 'assets/icon/import-icon.svg'

import styles from './styles.module.scss'
import { useLanguage } from 'providers'

export interface EcostabBoxProps {
  box: Store.MachineBox
  value?: number | null | undefined
  valuePerc?: number
  timestamp?: string
  online?: boolean
  unit?: string
}

export const EcostabBox: React.FC<EcostabBoxProps> = ({ box, value, valuePerc, timestamp, unit }) => {
  const { t } = useTranslation()
  const lang = useLanguage()

  const getDateLabel = () => (
    <>
      {t('general.surveyDate')} {timestamp ? timestamp : '-'}
    </>
  )

  return (
    <Card className={styles['ecostab-box']} bordered={false}>
      <Typography.Title level={5} className="card-subtitle card-subtitle--top">
        {getBoxTitle(box?.mainValueLabel as BoxType, t)}
      </Typography.Title>

      <Space className="content-wrapper" align="start">
        <div className="icon-wrapper">
          {box?.isInput ? (
            <ImportIcon className="box-import-icon" />
          ) : (
            <ExportIcon className="box-export-icon" />
          )}
        </div>
        <div>
          <Typography.Text className="box-value">
            <span className="numeric">
              {isNumeric(value) ? new Intl.NumberFormat(lang).format(toNumber(value)) : ''}
            </span>
            <span className="unit">{unit}</span>
          </Typography.Text>

          {valuePerc && (
            <Typography.Text className="box-value">
              <span className="numeric">{valuePerc}</span>
              <span className="unit">{t('metricUnit.percentage')}</span>
            </Typography.Text>
          )}
        </div>
      </Space>
      <div className="bottom-bar-wrapper">
        <Typography.Paragraph className="card-date">{getDateLabel()}</Typography.Paragraph>
      </div>
    </Card>
  )
}

export default EcostabBox
