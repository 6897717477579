import React from 'react'
import dayjs from 'dayjs'

import { config } from 'core'
import EcostabBox from 'components/EcostabBox'
import { BoxType } from 'types/enums'

import type * as Store from 'types/store'
import { Button, Carousel, CarouselProps } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { useTranslation } from 'react-i18next'
interface SavingsBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeSavingsData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export const SavingsBox: React.FC<SavingsBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()
  if (realtimeError) {
    // TODO: better error handling
  }

  const carouselConfig: CarouselProps = {
    infinite: false,
    variableWidth: true,
    dots: false,
    draggable: true,
    arrows: true,
    nextArrow: <Button type="text" icon={<ArrowIcon />} />,
    prevArrow: <Button type="text" icon={<ArrowIcon />} />,
    responsive: [
      {
        breakpoint: 379,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="realtime-boxes">
      <Carousel {...carouselConfig}>
        <EcostabBox
          box={boxes?.find((box) => box?.mainValueLabel === BoxType.SavingLifetime)}
          value={realtimeData?.metrics?.savingLifetime}
          valuePerc={realtimeData?.metrics?.savingLifetimePerc}
          timestamp={realtimeData?.ts && dayjs(realtimeData.ts).format(config.getDateTimeFormat())}
          unit={t('metricUnit.energy')}
        />
        <EcostabBox
          box={boxes?.find((box) => box?.mainValueLabel === BoxType.PotenzaAttiva)}
          value={realtimeData?.metrics?.powerActiveOutAvg}
          timestamp={realtimeData?.ts && dayjs(realtimeData.ts).format(config.getDateTimeFormat())}
          unit={t('metricUnit.power')}
        />
        <EcostabBox
          box={boxes?.find((box) => box?.mainValueLabel === BoxType.EnergiaAttivaTotale)}
          value={realtimeData?.metrics?.energyActiveOutTotal}
          timestamp={realtimeData?.ts && dayjs(realtimeData.ts).format(config.getDateTimeFormat())}
          unit={t('metricUnit.energy')}
        />
      </Carousel>
    </div>
  )
}

export default SavingsBox
