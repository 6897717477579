import { EcostabBox } from 'components/EcostabBox'
import React from 'react'
import { BoxType } from 'types/enums'
import { config } from 'core'
import dayjs from 'dayjs'
import type * as Store from 'types/store'
import { useTranslation } from 'react-i18next'

interface EnergyBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeEnergyData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

const EnergyBox: React.FC<EnergyBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()
  if (realtimeError) {
    // TODO: better error handling
  }

  return (
    <div className="realtime-boxes">
      <EcostabBox
        box={boxes?.find((box) => box?.mainValueLabel === BoxType.EnergiaAttivaTotale)}
        value={realtimeData?.metrics?.energyActiveOutTotal}
        timestamp={realtimeData?.ts && dayjs(realtimeData.ts).format(config.getDateTimeFormat())}
        unit={t('metricUnit.energy')}
      />
    </div>
  )
}

export default EnergyBox
